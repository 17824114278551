import React, { Suspense } from 'react'
import ShareLayout from '../layout/components/ShareLayout'
import { PageLoader } from '@/components/Loading'

const ShareReports = React.lazy(async () => await import('../ShareReports'))

const ShareReportPage = (): React.ReactElement => {
  return (
    <ShareLayout>
      <Suspense fallback={<PageLoader />}>
        <ShareReports />
      </Suspense>
    </ShareLayout>
  )
}

export default ShareReportPage
