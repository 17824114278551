import AuthRegLayout from '@/apps/layout/components/AuthRegLayout'
import { PageLoader } from '@/components/Loading'
import YandexMetrika from '@/components/YandexMetrika'
import { loginImage } from '@/images'
import React, { Suspense } from 'react'

const Login = React.lazy(async () => await import('../Login/Login'))

const LoginPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={loginImage}>
        <Suspense fallback={<PageLoader />}>
          <Login />
        </Suspense>
      </AuthRegLayout>
    </>
  )
}

export default LoginPage
