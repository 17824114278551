import React, { Suspense } from 'react'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const EditProfile = React.lazy(async () => await import('../EditProfile'))

const Profile = (): React.ReactElement => {
  return (
    <UserLayout>
      <Suspense fallback={<PageLoader />}>
        <EditProfile />
      </Suspense>
    </UserLayout>
  )
}

export default Profile
