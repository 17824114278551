import React, { Suspense } from 'react'
import { regImage } from '@/images'
import AuthRegLayout from '../layout/components/AuthRegLayout'
import YandexMetrika from '@/components/YandexMetrika'
import { PageLoader } from '@/components/Loading'

const PasswordRecovery = React.lazy(async () => await import('../PasswordRecovery'))

const PasswordRecoveryPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={regImage}>
        <Suspense fallback={<PageLoader />}>
          <PasswordRecovery />
        </Suspense>
      </AuthRegLayout>
    </>
  )
}

export default PasswordRecoveryPage
