import { useLayoutEffect, useState } from 'react'
import { MEDIA_SCREENS, MEDIA_ACC_INIT } from '@/const'
import { objectKeys } from '@/utils'

interface IUseMatchMediaReturn extends Record<keyof typeof MEDIA_SCREENS, boolean> {}

export const useMatchMedia = (): IUseMatchMediaReturn => {
  const queriesMedia = objectKeys(MEDIA_SCREENS).map((key) => MEDIA_SCREENS[key])

  const mediaQueryLists: MediaQueryList[] = queriesMedia.map((query) => matchMedia(query))

  const getValues = (): boolean[] => mediaQueryLists.map((list) => list.matches)

  const [values, setValues] = useState(getValues)

  useLayoutEffect(() => {
    const handler = (): void => setValues(getValues)

    mediaQueryLists.forEach((list) => list.addEventListener('change', handler))

    return (): void =>
      mediaQueryLists.forEach((list) =>
        list.removeEventListener('change', handler)
      )
  })

  return objectKeys(MEDIA_SCREENS).reduce<IUseMatchMediaReturn>(
    (acc, screen, index) => ({
      ...acc,
      [screen]: values[index]
    }),
    MEDIA_ACC_INIT
  )
}
