import AuthRegLayout from '@/apps/layout/components/AuthRegLayout'
import { regImage } from '@/images'
import React, { Suspense } from 'react'
import YandexMetrika from '@/components/YandexMetrika'
import { PageLoader } from '@/components/Loading'

const RegistrationConfirm = React.lazy(
  async () => await import('../RegistrationConfirm')
)

const RegistrationConfirmPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={regImage}>
        <Suspense fallback={<PageLoader />}>
          <RegistrationConfirm />
        </Suspense>
      </AuthRegLayout>
    </>
  )
}

export default RegistrationConfirmPage
