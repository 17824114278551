import { usePage } from '@/hooks'
import React, { Suspense } from 'react'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const AddOrEditProject = React.lazy(async () => await import('../AddOrEditProject'))

const AddOrEditProjectPage = (): React.ReactElement => {
  const page = usePage()
  const obj = page.init_state.object
  const companyTitle = obj.title
  const companyImage = obj.image

  return (
    <UserLayout>
      <Suspense fallback={<PageLoader />}>
        <AddOrEditProject
          companyTitle={companyTitle}
          companyImage={companyImage}
        />
      </Suspense>
    </UserLayout>
  )
}

export default AddOrEditProjectPage
