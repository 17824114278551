import React, { Suspense } from 'react'
import CommonLayout from '../layout/components/CommonLayout'
import { forbidden } from '@/images'
import { useIntl } from 'react-intl'
import { PageLoader } from '@/components/Loading'

const ErrorAuth = React.lazy(async () => await import('../Errors/ErrorAuth'))

const ErrorPage403 = (): React.ReactElement => {
  const intl = useIntl()

  return (
    <CommonLayout>
      <Suspense fallback={<PageLoader />}>
        <ErrorAuth
          numberError={403}
          textError={intl.formatMessage({
            id: 'error.forbidden',
            defaultMessage: 'Forbidden...'
          })}
          descriptionTitle={intl.formatMessage({
            id: 'error.page_not_available',
            defaultMessage: 'The page is not available'
          })}
          descriprionText={intl.formatMessage({
            id: 'error.text_403',
            defaultMessage:
              'You do not have access to this page. Contact the administrator.'
          })}
          image={forbidden}
        />
      </Suspense>
    </CommonLayout>
  )
}

export default ErrorPage403
