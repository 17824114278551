import React from 'react'
import classNames from 'classnames'
import style from './styles/loading.module.scss'

interface IProps {
  children: React.ReactNode
  className?: string
}

const ShimmerWrap = ({
  children,
  className = ''
}: IProps): React.ReactElement => {
  return (
    <span
      className={classNames({
        [style.wrapper]: true,
        [className]: Boolean(className)
      })}
    >
      {children}
    </span>
  )
}

export default ShimmerWrap
