import React, { Suspense } from 'react'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const Companies = React.lazy(async () => await import('../Companies'))

const CompaniesPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <Suspense fallback={<PageLoader />}>
        <Companies />
      </Suspense>
    </UserLayout>
  )
}

export default CompaniesPage
