import { usePage } from '@/hooks'
import React, { Suspense } from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const AddOrEditMilestone = React.lazy(async () => await import('../AddOrEditMilestone'))

const MilestonePageUpdate = (): React.ReactElement => {
  const page = usePage()

  const title = page.init_state.object.title
  const projectId = page.init_state.object.project
  const id = page.init_state.object.id

  return (
    <UserLayout>
      <ProjectLayout>
        <Suspense fallback={<PageLoader />}>
          <AddOrEditMilestone project={projectId} title={title} id={id} />
        </Suspense>
      </ProjectLayout>
    </UserLayout>
  )
}

export default MilestonePageUpdate
