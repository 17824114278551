import { useEffect, useState } from 'react'

interface IUseLocalFile {
  blobURL: string | undefined
}

type TLocalFile =
  | {
    isLocal: true
    blob: Blob
  }
  | {
    isLocal: false
    blob: undefined
  }

interface TLocalFileStatus {
  isLoading: boolean
  error: string | null
}

type TUseLocalFile = TLocalFile & TLocalFileStatus

const fileState: TUseLocalFile = {
  isLocal: false,
  blob: undefined,
  isLoading: true,
  error: null
}

/**
 * Хук useLocalFile загружает и обрабатывает локальный файл, используя предоставленный URL-адрес Blob.
 *
 * @param {Object} params - Параметры хука.
 * @param {string | undefined} params.blobURL - URL-адрес Blob файла для загрузки.
 *
 * @returns {Object}  Объект состояния, содержащий:
 *  - isLocal: boolean - флаг, указывающий, является ли файл локальным.
 *  - blob: Blob | undefined - загруженный Blob файл или undefined, если загрузка не удалась.
 *  - isLoading: boolean - состояние загрузки, true, если файл загружается.
 *  - error: string | null - сообщение об ошибке, если загрузка не удалась, иначе null.
 *
 * Хук следит за изменениями в `blobURL` и перезагружает файл при его изменении.
 * Если `blobURL` равен undefined или не начинается с 'blob', загрузка не происходит.
 */
export const useLocalFile = ({ blobURL }: IUseLocalFile): TUseLocalFile => {
  const [state, setState] = useState<TUseLocalFile>(fileState)

  useEffect(() => {
    if (blobURL === undefined || !blobURL?.startsWith('blob')) {
      setState({ ...fileState, isLoading: false })
      return
    }

    let fileBlob: Blob | undefined
    fetch(blobURL)
      .then(async (response) => await response.blob())
      .then((blob) => {
        fileBlob = blob
        setState({
          ...fileState,
          isLocal: true,
          blob: fileBlob,
          isLoading: false
        })
      })
      .catch((error) => {
        setState({
          ...fileState,
          isLoading: false,
          error: error.message
        })
      })
  }, [blobURL])

  return state
}
