import React from 'react'
import style from './styles/dropdown.module.scss'
import classNames from 'classnames'
import Dropdown from './Dropdown'
import DropdownMenu from './DropdownMenu'
import MenuItem from '../MenuItem/MenuItem'
import Icon from '../Icon/Icon'
import { STEP_STATUSES_ARRAY } from '@/const'
import { FormattedMessage } from 'react-intl'
import { TBadgeStatus } from '../../interfaces/Types'

interface IProps {
  status: TBadgeStatus
  handleChange: (value) => void
  className?: string
  classNameDropdown?: string
}

const DropdownStatus = ({
  status,
  handleChange,
  className = '',
  classNameDropdown = ''
}: IProps): React.ReactElement => {
  const value = status.toLocaleLowerCase()
  return (
    <Dropdown className={classNameDropdown}>
      <button
        slot='trigger'
        className={classNames({
          [style.status]: true,
          [style[`status--${value}`]]: true,
          [className]: Boolean(className)
        })}
      >
        <FormattedMessage id={`status.test.${value}`} defaultMessage={value} />

        <Icon slot='icon-right' src='arrow_down' />
      </button>

      <DropdownMenu className={style.menu}>
        {STEP_STATUSES_ARRAY.map((el) => {
          return (
            <MenuItem
              value={el}
              key={el}
              className={style.status__item}
              onClick={() => handleChange(el)}
            >
              <div
                className={classNames({
                  [style.rect]: true,
                  [style[`status--${el}`]]: true
                })}
              />

              <FormattedMessage id={`status.test.${el}`} defaultMessage={el} />
            </MenuItem>
          )
        })}
      </DropdownMenu>
    </Dropdown>
  )
}

export default DropdownStatus
