import React from 'react'
import classNames from 'classnames'
import DownloadAnimated from '@/components/Icon/AnimatedIcons/DownloadAnimated'
import Icon from '@/components/Icon/Icon'
import { TDownloadStatus } from '@/interfaces/Notifies'
import { IMPORT_STATUSES } from '@/const'
import style from './styles/download.module.scss'

interface IProps<T> {
  children: React.ReactNode
  data?: T[]
  togglePanel: () => void
  isOpen: boolean
  status?: TDownloadStatus
  className?: string
  position?: 'left' | 'right'
}

const ImportPanel = <T extends {}>(
  {
    children,
    data,
    togglePanel,
    isOpen,
    status,
    position = 'right',
    className = ''
  }: IProps<T>,
  ref
): React.ReactElement => {
  if (data === undefined || data?.length === 0) return <></>
  return (
    <div
      ref={ref}
      className={classNames({
        [style.download]: true,
        [className]: Boolean(className)
      })}
    >
      <button
        className={style.download__button}
        type='button'
        onClick={togglePanel}
      >
        {status === IMPORT_STATUSES.queue || status === IMPORT_STATUSES.cancel
          ? (
            <Icon src='download' className={style.download__queue} />
            )
          : null}

        {status === IMPORT_STATUSES.start ? <DownloadAnimated /> : null}

        {status === IMPORT_STATUSES.finish
          ? (
            <Icon src='checked' className={style.download__success} />
            )
          : null}

        {status === IMPORT_STATUSES.error
          ? (
            <Icon src='attention' className={style.download__error} />
            )
          : null}
      </button>

      {isOpen
        ? (
          <div
            className={classNames({
              [style.download__panel]: true,
              [style[`download__panel--${position}`]]: Boolean(className)
            })}
          >
            {children}
          </div>
          )
        : null}
    </div>
  )
}

export default React.forwardRef(ImportPanel)
