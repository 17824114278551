import { usePage } from '@/hooks'
import React, { Suspense } from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const AddOrEditCase = React.lazy(async () => await import('../AddOrEditCase'))

const CreateCasePage = (): React.ReactElement => {
  const page = usePage()
  const id = page.init_state.object.id

  return (
    <UserLayout>
      <ProjectLayout>
        <Suspense fallback={<PageLoader />}>
          <AddOrEditCase id={id} />
        </Suspense>
      </ProjectLayout>
    </UserLayout>
  )
}

export default CreateCasePage
