import React from 'react'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Link from '@/components/Link'
import { IProject } from '@/interfaces/Project'
import style from '../styles/header.module.scss'

interface IProps {
  project: IProject
}

const Cancel = ({ project }: IProps): React.ReactElement => {
  return (
    <div className={style.download__item}>
      <div className={style.download__process}>
        <Icon
          src='clear'
          className={classNames(
            style.download__icon,
            style.download__icon_cancel
          )}
        />
        <div className={style.download__description}>
          <span className={style.download__text}>
            <FormattedMessage
              id='download.export_file.cancel'
              defaultMessage='Export file canceled from <project></project>'
              values={{
                project: () => (
                  <Link
                    to={project.absolute_url}
                    className={style.download__projectlink}
                  >
                    {project.title}
                  </Link>
                )
              }}
            />
          </span>
        </div>
      </div>
    </div>
  )
}
export default Cancel
