import React from 'react'
import classNames from 'classnames'
import { TSizeComponent } from '@/interfaces/Types'
import style from './styles/loading.module.scss'

interface IProps {
  className?: string
  size?: TSizeComponent
  slot?: string
}

const LoadingDots = ({ className, slot, size = 'xxs' }: IProps): React.ReactElement => {
  return (
    <div className={classNames(style.dots, style[`dots--${size}`], className)} slot={slot}>
      <div className={style.dots__first} />
      <div className={style.dots__second} />
      <div className={style.dots__third} />
    </div>
  )
}

export default LoadingDots
