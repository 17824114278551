/**
   * Хук для отслеживания клика вне функции
   * @param {*} ref - ref компонента вне которого нажали
   * @param {*} onOuterClick - передаваемая функция которая срабатывает после нажатия вне компонента
   */
import { RefObject, useEffect } from 'react'

export const useOuterClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  onOuterClick: () => void
): void => {
  const handleClick = (e: MouseEvent): void => {
    const { current } = ref

    if ((current != null) && !current.contains(e.target as Node) && onOuterClick !== undefined) {
      onOuterClick()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  })
}

export default useOuterClick
