import { runtimeConfig } from '@/config'
import { Cookies } from '@garpix/cms'
import ReconnectingWebSocket from 'reconnecting-websocket'
import useSWRSubscription from 'swr/subscription'

const WS_URL: string = runtimeConfig.WS_URL

/** keySubscription - обязательный ключ, который должен быть  уникальным для каждого вызова хука, иначе будет конфликт с другом вызовом */

export const useWebSocket = (
  handleChange: (data?: any) => void,
  keySubscription: string
): void => {
  useSWRSubscription(['/garpix_workflow/', keySubscription], (key) => {
    const token = Cookies.getCookie('gx_access_token') ?? ''
    const socket = new ReconnectingWebSocket(
      `${WS_URL}${key[0]}?token=${token}`,
      [],
      {
        maxRetries: 10
      }
    )

    socket.addEventListener('message', (event): void => {
      const data = JSON.parse(event.data)
      handleChange(data)
    })

    return () => socket.close()
  })
}
