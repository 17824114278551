import React, { Suspense } from 'react'
import ShareLayout from '../layout/components/ShareLayout'
import { PageLoader } from '@/components/Loading'

const ShareReportDetail = React.lazy(
  async () => await import('../ShareReportDetail')
)

const ShareReportRunPage = (): React.ReactElement => {
  return (
    <ShareLayout>
      <Suspense fallback={<PageLoader />}>
        <ShareReportDetail />
      </Suspense>
    </ShareLayout>
  )
}

export default ShareReportRunPage
