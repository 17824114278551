import React from 'react'
import style from './styles/notification_item.module.scss'
import { INotifiesInviteProjectData } from '@/interfaces/Notifies'
import { ITimeSince } from '@/interfaces/Utils'
import NotifyTime from './components/NotifyTime'
import { FormattedMessage } from 'react-intl'
import Tooltip from '../Tooltip'
import { getInitials } from '../UserName'
import { AvatarProject, AvatarUser } from '../Avatar'
import Link from '../Link'

interface IProps {
  data: INotifiesInviteProjectData
  time: ITimeSince
}

const NotifyInviteProject = ({ time, data }: IProps): React.ReactElement => {
  const { project, author, role } = data
  const initialsUser = getInitials(
    author.first_name,
    author.last_name,
    author.email
  )

  return (
    <>
      <Tooltip content={`${author.first_name} ${author.last_name}`}>
        <AvatarUser
          src={author.avatar}
          className={style.item__avatar}
          initials={initialsUser}
        />
      </Tooltip>

      <div className={style.item__content}>
        <p className={style.info}>
          <FormattedMessage
            id='notifications.item.project_added'
            defaultMessage='You have been <span>added</span> to the project as a {role}'
            values={{
              span: (chunks) => (
                <span className={style.invtext__invite}>{chunks}</span>
              ),
              role: <span className={style.invtext__invite}>{role}</span>
            }}
          />
        </p>

        <div className={style.run}>
          <AvatarProject src={project.avatar} />

          <Link to={project.absolute_url}>{project.title}</Link>
        </div>

        <NotifyTime time={time} />
      </div>
    </>
  )
}

export default NotifyInviteProject
