import React from 'react'

/** Компонент для использования в <Suspense> страниц.
 * Пока пустой див, т.к. надо выносить нормальные скелетоны из компонентов.
 */
const PageLoader = (): React.ReactElement => {
  return <div />
}

export default PageLoader
