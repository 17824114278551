import { IPrompt } from '@/interfaces/ObjectPage'
import useGlobalConfig from './useGlobalConfig'

const usePrompt = (): IPrompt => {
  const value = useGlobalConfig()

  return {
    promptContent: value.prompt_content,
    promptHeader: value.prompt_header,
    documentationLink: value.documentation_link
  }
}

export default usePrompt
