import { defaultUser } from '@/images'
import classNames from 'classnames'
import React from 'react'
import style from './styles/avatar.module.scss'

interface IProps {
  src?: string | null
  initials?: string
  size?: 'small' | 'medium' | 'large' | 'xl' | 'xxl' | 'xxxl'
  className?: string
}

type TBorder = 'violet' | 'light'

const AvatarUser = ({
  src = null,
  size = 'medium',
  className = '',
  initials = ''
}: IProps): React.ReactElement => {
  let border: TBorder | '' = ''

  if (src !== null) border = 'light'
  else if (initials !== '') border = 'violet'
  return (
    <>
      {initials !== '' && src === null
        ? (
          <div
            className={classNames({
              [style.initials]: true,
              [style[`avatar--${size}`]]: true,
              [style[`initials--${size}`]]: true,
              [style[`border--${border}`]]: Boolean(border),
              [style[`border--${size}`]]: Boolean(border),
              [className]: Boolean(className)
            })}
          >
            {initials}
          </div>
          )
        : (
          <img
            className={classNames({
              [style.avatar]: true,
              [style[`avatar--${size}`]]: true,
              [style[`border--${border}`]]: Boolean(border),
              [style[`border--${size}`]]: Boolean(border),
              [className]: Boolean(className)
            })}
            src={src ?? defaultUser}
            alt='User avatar'
          />
          )}
    </>
  )
}

export default AvatarUser
