import React, { useState } from 'react'
import classNames from 'classnames'

import Button from '@/components/Button/Button'
import Icon from '@/components/Icon/Icon'
import ProjectDescription from './ProjectDescription/ProjectDescription'
import ProjectMenu from './ProjectMenu/ProjectMenu'

import { sidebarService } from '../service'

import style from './styles/sidebar.module.scss'

const Sidebar = ({ sidebarClassName = '' }): React.ReactElement => {
  const [isBig, setIsBig] = useState(sidebarService.open)

  // фиксит вертикальные прыжки контента в сайдбаре во время открытия
  const toggleOpenSidebar = (): void => {
    try {
      if (!sidebarService.open) {
        setTimeout(() => {
          setIsBig(!isBig)
        }, 300)
      } else setIsBig(!isBig)
    } finally {
      sidebarService.toggle()
    }
  }

  return (
    <div
      className={classNames({
        [style.sidebar]: true,
        [style['sidebar--opened']]: sidebarService.open,
        [sidebarClassName]: Boolean(sidebarClassName)
      })}
    >
      <div className={style.sidebar__scroll}>
        <ProjectDescription isBig={isBig} />
        <ProjectMenu isBig={isBig} />
      </div>

      <Button
        theme='light'
        className={style.sidebar__button}
        onClick={toggleOpenSidebar}
      >
        <Icon
          src='back'
          className={classNames({
            [style.sidebar__icon]: true,
            [style['sidebar__icon--opened']]: sidebarService.open
          })}
        />
      </Button>
    </div>
  )
}

export default Sidebar
