import React, { Suspense } from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const Milestones = React.lazy(async () => await import('../Milestones/Milestones'))

const ProjectMilestones = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <Suspense fallback={<PageLoader />}>
          <Milestones />
        </Suspense>
      </ProjectLayout>
    </UserLayout>
  )
}

export default ProjectMilestones
