import React, { Suspense } from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const CasesSuites = React.lazy(async () => await import('../SuitesCases/CasesSuites'))

const ProjectCasesSuites = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <Suspense fallback={<PageLoader />}>
          <CasesSuites />
        </Suspense>
      </ProjectLayout>
    </UserLayout>
  )
}

export default ProjectCasesSuites
