import React, { Suspense } from 'react'
import UserLayout from '../layout/components/UserLayout'
import { useObjectPage } from '@/hooks'
import { PageLoader } from '@/components/Loading'

const AddOrEditCompany = React.lazy(async () => await import('../AddOrEditCompany'))

const CompanyUpdatePage = (): React.ReactElement => {
  const { id } = useObjectPage()

  return (
    <UserLayout>
      <Suspense fallback={<PageLoader />}>
        <AddOrEditCompany id={id} />
      </Suspense>
    </UserLayout>
  )
}

export default CompanyUpdatePage
