import Icon from '@/components/Icon/Icon'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import Link from '@/components/Link'
import style from './styles/header.module.scss'

const TechWorksInfo = (): React.ReactElement => {
  const { faq_url: faqUrl } = useGlobalConfig()

  const tooltipText = (
    <span className={style.techwork__content}>
      <FormattedMessage
        id='header.tech_work'
        defaultMessage='Technical work is underway. Possible interruptions in the system operation. If you have found an error, please report it to <link>Support</link>.'
        values={{
          link: (chunks) => (
            <Link to={faqUrl} className={style.techwork__link}>
              {chunks}
            </Link>
          )
        }}
      />
    </span>
  )

  return (
    <div className={style.techwork__block}>
      <Icon src='attention2' size='small' className={style.techwork__icon} />
      <span className={style.techwork__content}>{tooltipText}</span>
    </div>
  )
}

export default TechWorksInfo
