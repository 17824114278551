import React from 'react'
import style from '../styles/header.module.scss'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import Link from '@/components/Link'
import { IProject } from '@/interfaces/Profile'

interface IProps {
  text: string
  linkDownload: string
  project: IProject
}
const Finish = ({
  text,
  linkDownload,
  project
}: IProps): React.ReactElement => {
  return (
    <div className={style.download__item}>
      <div className={style.download__finish}>
        <Icon src='text_file' className={style.download__icon} />

        <div className={style.download__description}>
          <span className={style.download__gray}>
            <FormattedMessage
              id='download.export_file.finish'
              defaultMessage='Export file from <project></project>'
              values={{
                project: () => (
                  <Link
                    to={project.absolute_url}
                    className={style.download__projectlink}
                  >
                    {project.title}
                  </Link>
                )
              }}
            />
          </span>

          <span>{text}</span>
        </div>
      </div>

      <Link
        target='_blank'
        to={linkDownload}
        download
        className={style.download__link}
      >
        <Icon src='download' className={style.download__downloadicon} />

        <FormattedMessage id='common.download' defaultMessage='Download' />
      </Link>
    </div>
  )
}
export default Finish
