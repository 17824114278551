import AuthRegLayout from '@/apps/layout/components/AuthRegLayout'
import { regImage } from '@/images'
import React, { Suspense } from 'react'
import YandexMetrika from '@/components/YandexMetrika'
import { PageLoader } from '@/components/Loading'

const EmailConfirm = React.lazy(async () => await import('../EmailConfirm'))

const EmailConfirmPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={regImage}>
        <Suspense fallback={<PageLoader />}>
          <EmailConfirm />
        </Suspense>
      </AuthRegLayout>
    </>
  )
}

export default EmailConfirmPage
