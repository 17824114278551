import AuthRegLayout from '@/apps/layout/components/AuthRegLayout'
import { regImage } from '@/images'
import React, { Suspense } from 'react'
import YandexMetrika from '@/components/YandexMetrika'
import { PageLoader } from '@/components/Loading'

const Registration = React.lazy(async () => await import('../Registration'))

const RegistrationPage = (): React.ReactElement => {
  return (
    <>
      <YandexMetrika />
      <AuthRegLayout image={regImage}>
        <Suspense fallback={<PageLoader />}>
          <Registration />
        </Suspense>
      </AuthRegLayout>
    </>
  )
}

export default RegistrationPage
