import { usePage } from '@/hooks'
import React, { Suspense } from 'react'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const AddOrEditProject = React.lazy(
  async () => await import('../AddOrEditProject')
)

const AddOrEditProjectPage = (): React.ReactElement => {
  const page = usePage()
  const obj = page.init_state.object
  const id = obj.id
  const companyTitle = obj.company.title
  const companyImage = obj.company.image

  return (
    <UserLayout>
      <Suspense fallback={<PageLoader />}>
        <AddOrEditProject
          companyTitle={companyTitle}
          id={id}
          companyImage={companyImage}
        />
      </Suspense>
    </UserLayout>
  )
}

export default AddOrEditProjectPage
