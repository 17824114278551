import useCurrentUser from '@/hooks/useCurrentUser'
import React, { useEffect, useRef } from 'react'
import UserDropdown from '../UserDropdown/UserDropdown'
import HeaderMenu from './HeaderMenu/HeaderMenu'
import Notifications from '@/apps/Notifications'
import { Logo } from './Logo'
import useGlobalConfig from '@/hooks/useGlobalConfig'
import TechWorksInfo from './TechWorksInfo'
import PromptPanel from './PromptPanel'
import DownloadPanel from './DownloadPanel'
import style from './styles/header.module.scss'

const Header = (): React.ReactElement => {
  const { currentUser } = useCurrentUser()
  const { show_maintenance_message: showMaintenanceMessage } =
    useGlobalConfig()

  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const headerHeight = headerRef?.current?.offsetHeight ?? 0
    document.documentElement.style.setProperty(
      '--header-height',
      `${headerHeight}px`
    )

    const handlerResizable = (): void => {
      const headerHeight = headerRef?.current?.offsetHeight ?? 0
      document.documentElement.style.setProperty(
        '--header-height',
        `${headerHeight}px`
      )
    }

    window.addEventListener('resize', handlerResizable)
    return () => {
      window.removeEventListener('resize', handlerResizable)
    }
  }, [])

  return (
    <div className={style.header__wrapper} ref={headerRef}>
      <header className={style.header}>
        <Logo />

        <HeaderMenu />

        <div className={style.header__right}>
          <PromptPanel className={style.header__item} />

          <DownloadPanel />

          {currentUser !== undefined
            ? (
              <>
                <Notifications className={style.header__item} />

                <UserDropdown user={currentUser} />
              </>
              )
            : null}
        </div>
      </header>

      {showMaintenanceMessage ? <TechWorksInfo /> : null}
    </div>
  )
}

export default Header
