import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import Icon, { TIcon } from '@/components/Icon/Icon'
import Title from '@/components/Title'
import { Accordion } from '@/components/Accordion'
import Link from '@/components/Link'
import { AvatarProject } from '@/components/Avatar'
import ProjectDropdown from './ProjectDropdown'
import { useProjectObject } from '@/hooks'
import { TProjectLinks } from '@/interfaces/Types'
import style from './styles/projectdescription.module.scss'

const icons: { [key in TProjectLinks]: TIcon } = {
  jira: 'task',
  confluence: 'knowledge',
  gitlab: 'git'
}

const ProjectDescription = ({ isBig }): React.ReactElement => {
  const projectObj = useProjectObject()
  const intl = useIntl()
  const projectInfo = useProjectObject()

  const urls = projectObj?.urls ?? []

  return (
    <div
      className={classNames({
        [style.desc]: true,
        [style['desc--small']]: isBig === false
      })}
    >
      <div
        className={classNames({
          [style.desc__actions]: true,
          [style['desc__actions--small']]: isBig === false
        })}
      >
        <AvatarProject
          size={isBig === true ? 'xl' : 'large'}
          src={projectInfo.image}
        />
        {isBig === true
          ? (
            <ProjectDropdown />
            )
          : null}
      </div>
      {isBig === true
        ? (
          <Link to={projectInfo.absolute_url} className={style.desc__mainlink}>
            <Title className={style.desc__title} type='h2'>
              {projectInfo.title}
            </Title>
          </Link>
          )
        : null}
      <div
        className={classNames({
          [style.desc__codewrap]: true,
          [style['desc__codewrap--small']]: isBig === false
        })}
      >
        <div
          className={classNames({
            [style.desc__code]: true,
            [style['desc__code--small']]: isBig === false
          })}
        >
          {projectInfo?.code !== undefined
            ? (
              <>{isBig === true ? <> {`id: ${projectInfo?.code} `}</> : null}</>
              )
            : null}
        </div>
        <div
          className={classNames({
            [style.desc__links]: true,
            [style['desc__links--vertical']]: isBig === false
          })}
        >
          {urls
            .filter((url) => url.value !== '')
            .map((url) => {
              return (
                <a
                  key={url.id}
                  href={url.value}
                  target='_blank'
                  rel='noreferrer'
                >
                  <Icon src={icons[url.system]} />
                </a>
              )
            })}
        </div>
      </div>
      {projectInfo.description !== '' && isBig === true
        ? (
          <Accordion
            head={intl.formatMessage({
              id: 'sidebar.description',
              defaultMessage: 'Description'
            })}
          >
            <p className={style.desc__info}>{projectInfo.description}</p>
          </Accordion>
          )
        : null}
    </div>
  )
}

export default ProjectDescription
