import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import { IButtonProps } from './interfaces'
import { TTargetValue } from '@/interfaces/Types'

interface IProps extends IButtonProps {
  href: string
  target?: TTargetValue
}

const LinkButton = ({
  children,
  href,
  onClick,
  target = undefined,
  ...props
}: IProps): React.ReactElement => {
  const navigate = useNavigate()

  const handleClick = (e): void => {
    e.preventDefault()
    onClick?.(e)
    if (target !== undefined) {
      window.open(href, target)
    } else {
      navigate(href)
    }
  }

  return (
    <Button onClick={handleClick} href={href} {...props}>
      {children}
    </Button>
  )
}

export { LinkButton }
