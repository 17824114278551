import React, { Suspense } from 'react'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const Faq = React.lazy(async () => await import('../Faq'))

const FaqPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <Suspense fallback={<PageLoader />}>
        <Faq />
      </Suspense>
    </UserLayout>
  )
}

export default FaqPage
