import classNames from 'classnames'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import style from './styles/navlinkbutton.module.scss'

interface IProps {
  className?: string
  children: React.ReactNode
  link: string
  secondaryLink?: string
}

const HeaderNavLink = ({
  children,
  link,
  className = '',
  secondaryLink
}: React.PropsWithChildren<IProps>): React.ReactElement => {
  const location = useLocation()
  return (
    <NavLink
      className={({ isActive }) =>
        classNames({
          [style.headernavlink]: true,
          [style.headernavlink__active]:
            isActive || secondaryLink === location.pathname,
          [className]: Boolean(className)
        })}
      to={encodeURI(link)}
    >
      {children}
    </NavLink>
  )
}

export default HeaderNavLink
