import React from 'react'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import { IMPORT_STATUSES } from '@/const'
import { SpinnerWait } from '@/components/Loading'
import TextButton from '@/components/Button/TextButton'
import Link from '@/components/Link'
import { IProject } from '@/interfaces/Project'
import style from '../styles/header.module.scss'

interface IProps {
  countCases: number
  handleCancel: () => void
  project: IProject
}

const Queue = ({
  countCases,
  handleCancel,
  project
}: IProps): React.ReactElement => {
  return (
    <div className={style.download__item}>
      <div className={style.download__process}>
        <SpinnerWait />

        <div className={style.download__description}>
          <span className={style.download__text}>
            <FormattedMessage
              id='download.export_file.queue'
              defaultMessage='Export file from <project></project> has been added to the queue:'
              values={{
                project: () => (
                  <Link
                    to={project.absolute_url}
                    className={style.download__projectlink}
                  >
                    {project.title}
                  </Link>
                )
              }}
            />
          </span>

          <span className={style.download__count}>
            <Icon src='test_case' />

            {countCases}
          </span>
        </div>
      </div>

      {IMPORT_STATUSES.queue === status || IMPORT_STATUSES.start === status
        ? (
          <TextButton
            className={style.download__linkbutton}
            onClick={handleCancel}
          >
            <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
          </TextButton>
          )
        : null}
    </div>
  )
}
export default Queue
