import React from 'react'
import useModal from '@/hooks/useModal'
import { FormattedMessage } from 'react-intl'
import Icon from '@/components/Icon/Icon'
import { usePrompt } from '@/hooks'
import classNames from 'classnames'
import HtmlContent from '@/components/HtmlContent/HtmlContent'
import Link from '@/components/Link'
import style from './styles/header.module.scss'

interface IProps {
  className?: string
}

const PromptPanel = ({ className = '' }: IProps): React.ReactElement => {
  const { documentationLink, promptContent, promptHeader } = usePrompt()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()

  const handleClickPrompt = (): void => {
    isOpen ? handleCloseModal() : handleOpenModal()
  }

  if (
    documentationLink === null &&
    promptContent === null &&
    promptHeader === null
  ) { return <></> }

  return (
    <div className={style.prompt}>
      <button
        type='button'
        onClick={handleClickPrompt}
        className={classNames({
          [className]: Boolean(className)
        })}
      >
        <Icon src='prompt' className={style.prompt__button} />
      </button>

      {isOpen
        ? (
          <div className={style.prompt__panel}>
            <div className={style.prompt__title}>
              {promptHeader !== null
                ? (
                  <HtmlContent content={promptHeader} />
                  )
                : null}

              <button type='button' onClick={handleCloseModal}>
                <Icon src='clear' className={style.prompt__close} />
              </button>
            </div>

            {promptContent !== null
              ? (
                <HtmlContent
                  className={style.prompt__block}
                  content={promptContent}
                />
                )
              : null}

            {documentationLink !== null
              ? (
                <Link
                  target='_blank'
                  to={documentationLink}
                  className={style.prompt__bottom}
                >
                  <FormattedMessage
                    id='prompt.documentation'
                    defaultMessage='Documentation'
                  />
                </Link>
                )
              : null}
          </div>
          )
        : null}
    </div>
  )
}

export default PromptPanel
