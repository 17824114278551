import React, { Suspense } from 'react'
import ProjectLayout from '../layout/components/ProjectLayout'
import UserLayout from '../layout/components/UserLayout'
import { PageLoader } from '@/components/Loading'

const Members = React.lazy(async () => await import('../Members/Members'))

const ProjectMembers = (): React.ReactElement => {
  return (
    <UserLayout>
      <ProjectLayout>
        <Suspense fallback={<PageLoader />}>
          <Members />
        </Suspense>
      </ProjectLayout>
    </UserLayout>
  )
}

export default ProjectMembers
