import classNames from 'classnames'
import React from 'react'
import { useIntl } from 'react-intl'
import { TBageChart } from '../../../interfaces/Types'

import style from '../styles/status.module.scss'

interface IProps {
  status: TBageChart
  count: number
  percent: number
  className?: string
}

const BadgeFullStatus = ({
  status,
  count,
  percent,
  className = ''
}: IProps): React.ReactElement => {
  const intl = useIntl()

  const statusLowerCase = status.toLowerCase()

  const defaultMessage = status.charAt(0) + statusLowerCase.slice(1)

  const statusText = intl.formatMessage({
    id: `status.test.${statusLowerCase}`,
    defaultMessage
  })

  return (
    <div
      className={classNames({
        [style.badge]: true,
        [style['badge--full']]: true,
        [style[`test--${statusLowerCase}`]]: true,
        [className]: Boolean(className)
      })}
    >
      <span>{count}</span>
      <span className={style.badge__text}>{statusText}</span>
      <span>{percent}%</span>
    </div>
  )
}

export default BadgeFullStatus
