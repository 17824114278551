import Icon, { TIcon } from '@/components/Icon/Icon'
import { SidebarNavLink } from '@/components/NavLinkButton'
import classNames from 'classnames'
import React from 'react'
import style from './styles/projectmenu.module.scss'

interface IProps {
  isBig: boolean
  name: string
  href: string
  icon: TIcon
  className?: string
  isInAccordion?: boolean
}

const ProjectMenuLink = ({
  isBig,
  name,
  href,
  icon,
  className = undefined,
  isInAccordion
}: IProps): React.ReactElement => {
  const linkClasses = classNames(className, {
    [style['link--small']]: !isBig,
    [style.link__accordion]: isInAccordion
  })
  return (
    <SidebarNavLink key={name} link={href} className={linkClasses}>
      <Icon src={icon} size='small' />
      <span
        className={classNames({
          [style.link__text]: true,
          [style['link__text--hidden']]: !isBig
        })}
      >
        {name}
      </span>
    </SidebarNavLink>
  )
}

export default ProjectMenuLink
