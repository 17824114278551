import React, { useEffect, useRef, useState } from 'react'
import useModal from '@/hooks/useModal'
import { FormattedMessage } from 'react-intl'
import useSWR from 'swr'

import Icon from '@/components/Icon/Icon'
import { Process, Finish, Cancel, Queue } from './DownloadItems'
import { ImportPanel } from '@/components/DownloadPanel'
import { IMPORT_STATUSES } from '@/const'
import { INotifiesExportData, TDownloadStatus } from '@/interfaces/Notifies'
import { useStores, useWebSocket, useOuterClick } from '@/hooks'
import style from './styles/header.module.scss'

const DownloadPanel = (): React.ReactElement => {
  const { api } = useStores()
  const { isOpen, handleOpenModal, handleCloseModal } = useModal()
  const [status, setStatus] = useState<TDownloadStatus>()
  const [error, setError] = useState<string[]>()

  const panelRef = useRef(null)

  const handleClickDownload = (): void => {
    isOpen ? handleCloseModal() : handleOpenModal()
  }

  const { data, mutate } = useSWR(
    { _key: 'getExportPmiNotifiesDownloadPannel' },
    api.getExportPmiNotifies
  )

  const handleGetNotificationImport = (): void => {
    void mutate()
  }

  const handleCancelImport = (projectId: number): void => {
    api
      .cancelExportPmi(projectId)
      .catch((e) => setError(e.response.data.non_field_errors))
  }

  useEffect(() => {
    if (data !== undefined && data?.length > 0) {
      const status = data[0].data_json.status
      if (data[0].data_json.result === false) setStatus(IMPORT_STATUSES.error)
      else setStatus(status)
    }
  }, [data, mutate])

  useWebSocket(handleGetNotificationImport, 'ExportPMI')
  useOuterClick(panelRef, handleCloseModal)

  return (
    <ImportPanel
      status={status}
      data={data}
      togglePanel={handleClickDownload}
      isOpen={isOpen}
      className={style.download}
      position='left'
      ref={panelRef}
    >
      <div className={style.download__title}>
        <FormattedMessage id='titles.downloads' defaultMessage='Downloads' />

        <button type='button' onClick={handleCloseModal}>
          <Icon src='clear' className={style.download__close} />
        </button>
      </div>

      {error !== undefined
        ? (
          <>
            {error.map((err, index) => (
              <span key={index} className={style.download__error}>
                {err}
              </span>
            ))}
          </>
          )
        : null}

      <div className={style.download__block}>
        {data?.map((item) => {
          const {
            status,
            file_to_download: file,
            project,
            cases_count: casesCount
          }: INotifiesExportData = item.data_json
          const fileName = file?.split('/')?.at(-1) ?? ''

          const cancelImport = (): void => {
            handleCancelImport(project.id)
          }

          return (
            <div key={item.id} className={style.download__el}>
              {status === IMPORT_STATUSES.queue
                ? (
                  <Queue
                    countCases={casesCount}
                    project={project}
                    handleCancel={cancelImport}
                  />
                  )
                : null}

              {status === IMPORT_STATUSES.start
                ? (
                  <Process
                    countCases={casesCount}
                    project={project}
                    handleCancel={cancelImport}
                  />
                  )
                : null}

              {status === IMPORT_STATUSES.finish
                ? (
                  <Finish linkDownload={file} text={fileName} project={project} />
                  )
                : null}

              {status === IMPORT_STATUSES.cancel
                ? (
                  <Cancel project={project} />
                  )
                : null}

              {status === IMPORT_STATUSES.error ? <p>error</p> : null}
            </div>
          )
        })}
      </div>
    </ImportPanel>
  )
}

export default DownloadPanel
