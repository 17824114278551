import React, { Suspense } from 'react'
import UserLayout from '../layout/components/UserLayout'
import Container from '@/components/Container'
import { PageLoader } from '@/components/Loading'

const CompanyDetail = React.lazy(async () => await import('../CompanyDetail'))
const CompanyMembers = React.lazy(async () => await import('../CompanyMembers'))

const CompanyMembersPage = (): React.ReactElement => {
  return (
    <UserLayout>
      <Container>
        <Suspense fallback={<PageLoader />}>
          <CompanyDetail />
        </Suspense>

        <Suspense fallback={<PageLoader />}>
          <CompanyMembers />
        </Suspense>
      </Container>
    </UserLayout>
  )
}

export default CompanyMembersPage
