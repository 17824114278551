import React from 'react'
import { useNavigate } from 'react-router-dom'
import useSWR from 'swr'
import { FormattedMessage } from 'react-intl'
import Button from '@/components/Button/Button'
import { Dropdown, DropdownMenu } from '@/components/Dropdown'
import Icon from '@/components/Icon/Icon'
import MenuItem from '@/components/MenuItem/MenuItem'
import DropdownDivider from '@/components/Dropdown/DropdownDivider'
import { USER_PERMISSIONS, EDIT_PERMISSION_LEVEL } from '@/const'
import { useCurrentPermissions } from '@/hooks/useCurrentPermissions'
import { useProjectObject, useStores } from '@/hooks'
import useSidebar from '@/hooks/useSidebar'
import style from './styles/projectdescription.module.scss'

const ProjectDropdown = (): React.ReactElement => {
  const navigate = useNavigate()
  const { ProjectPageUpdate } = useSidebar()
  const projectObj = useProjectObject()
  const { api } = useStores()

  const canCurrentUserEditProject = useCurrentPermissions(
    USER_PERMISSIONS.project,
    EDIT_PERMISSION_LEVEL
  )

  const updateUrl = `${ProjectPageUpdate?.absolute_url}?next=${location.pathname}`

  const projectLink = window.location.origin + projectObj.absolute_url

  const { data, mutate } = useSWR(
    {
      id: projectObj.id,
      _key: 'getProject'
    },
    api.getProject
  )

  const handleClickEdit = (): void => {
    navigate(updateUrl)
  }

  const handleToggleMute = async (): Promise<void> => {
    if (data?.notifications_enabled !== undefined) {
      await api.turnProjectNotifications(
        projectObj.id,
        !data.notifications_enabled
      )
      await mutate()
    }
  }

  const handleCopyLink = (): void => {
    void navigator.clipboard.writeText(projectLink)
  }

  return (
    <Dropdown placement='right-start' distance={6}>
      <Button slot='trigger' circle theme='light'>
        <Icon src='dotsIcon' />
      </Button>
      <DropdownMenu className={style.menu}>
        {canCurrentUserEditProject
          ? (
            <MenuItem onClick={handleClickEdit}>
              <Icon src='edit' slot='prefix' />
              <FormattedMessage id='common.edit' defaultMessage='Edit' />
            </MenuItem>
            )
          : null}

        {data?.notifications_enabled === true
          ? (
            <MenuItem className={style.mute} onClick={handleToggleMute}>
              <Icon src='notificationMute' slot='prefix' />
              <FormattedMessage
                id='notifications.mute'
                defaultMessage='Mute notification'
              />
            </MenuItem>
            )
          : (
            <MenuItem className={style.unmute} onClick={handleToggleMute}>
              <Icon src='notification' slot='prefix' />
              <FormattedMessage
                id='notifications.unmute'
                defaultMessage='Unmute'
              />
            </MenuItem>
            )}

        <DropdownDivider />

        <MenuItem onClick={handleCopyLink}>
          <Icon src='attach' slot='prefix' />
          <FormattedMessage id='common.copy_link' defaultMessage='Copy link' />
        </MenuItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ProjectDropdown
