import React from 'react'
import { useLocation } from 'react-router-dom'
import { PageProvider, usePageContext } from '@garpix/cms'
import PasswordRecovery from './PasswordRecovery'
import Dashboard from './Dashboard'
import LoginPage from './Login'
import Landing from './Landing'
import ErrorPage500 from './ErrorPage500'
import ErrorPage404 from './ErrorPage404'
import ErrorPage403 from './ErrorPage403'
import ErrorPage401 from './ErrorPage401'
import { useStores } from '@/hooks'
import CustomVh from '@/components/CustomVh'
import ProjectUpdate from './ProjectUpdate'
import ProjectCreate from './ProjectCreate'
import ProjectMilestones from './ProjectMilestones'
import CaseCreate from './CaseCreate'
import CaseUpdate from './CaseUpdate'
import MilestoneUpdate from './MilestoneUpdate'
import MilestoneCreate from './MilestoneCreate'
import ProjectMembers from './Members'
import ProjectsPage from './Projects'
import ProjectRuns from './ProjectRuns'
import ProjectCasesSuites from './ProjectCasesSuites'
import ProjectPage from './ProjectPage'
import RunCreate from './RunCreate'
import Milestone from './Milestone'
import ReportsPage from './Reports'
import ReportDetailPage from './ReportDetail'
import Run from './Run'
import ProjectHistoryPage from './ProjectHistory'
import Case from './Case'
import CaseInRun from './CaseInRun'
import { TPageModel } from '@/interfaces/Types'
import { Modals } from '@/components/Modal'
import FaqPage from './Faq'
import Profile from './Profile'
import RunUpdate from './RunUpdate'
import AnotherProfile from './AnotherProfile'
import ShareReportPage from './ShareReport'
import ShareReportRunPage from './ShareReportRun'
import CompanyCreatePage from './CompanyCreate'
import RegistrationPage from './Registration'
import CompaniesPage from './Companies'
import RegistrationConfirmPage from './RegistrationConfirm'
import EmailConfirmPage from './EmailConfirm'
import CompanyUpdatePage from './CompanyUpdate'
import CompanyInvitesPage from './CompanyPageInvites'
import CompanyMembersPage from './CompanyPageMembers'
import CompanyProjectsPage from './CompanyPageProjects'
import ProjectIntegrationsPage from './ProjectIntegrationsPage'
import ProjectPageGitlabReports from './ProjectPageGitlabReports'
import ProjectRolesPage from './ProjectRoles'
import { Toaster } from 'react-hot-toast'

const PAGE_TYPES: { [key in TPageModel]: React.FC } = {
  Page: () => <div>Page</div>,
  HomePage: Dashboard,
  // HomePage: ProjectCasesSuites,
  RegistrationPage: RegistrationPage,
  RegistrationPageConfirmed: RegistrationConfirmPage,
  EmailConfirmationPage: EmailConfirmPage,
  LoginPage: LoginPage,
  PasswordRecoveryPage: PasswordRecovery,
  DashboardPage: Dashboard,
  LandingPage: Landing,
  ProjectPage: ProjectPage,
  // CompanyPage: () => <div>CompanyDetail</div>,
  CompanyPage: CompanyMembersPage,
  CompanyPageUpdate: CompanyUpdatePage,
  CompanyPageMembers: CompanyMembersPage,
  CompanyPageChangeOwner: () => <div>CompanyPageChangeOwner</div>,
  CompanyPageInvites: CompanyInvitesPage,
  CompanyPageProjects: CompanyProjectsPage,
  CompanyPageMembersDelete: () => <div>CompanyPageMembersDelete</div>,
  CompanyPageMembersInvite: () => <div>CompanyPageMembersInvite</div>,
  MilestonePage: Milestone,
  CompanyListPage: CompaniesPage,
  CompanyPageCreate: CompanyCreatePage,
  ProfilePage: Profile,
  AnotherProfilePage: AnotherProfile,
  ProjectListPage: ProjectsPage,
  ProjectPageCreate: ProjectCreate,
  ProjectPageUpdate: ProjectUpdate,
  ProjectPageGitlabReports: ProjectPageGitlabReports,
  ProjectPageMilestones: ProjectMilestones,
  ProjectPageMilestonesCreate: MilestoneCreate,
  MilestonePageUpdate: MilestoneUpdate,
  RunPage: Run,
  ProjectPageRuns: ProjectRuns,
  ProjectPageRunsCreate: RunCreate,
  RunPageUpdate: RunUpdate,
  ProjectPageCasesAndSuites: ProjectCasesSuites,
  ProjectPageSuitesCreate: () => <div>ProjectPageSuitesCreate</div>,
  ProjectPageCasesCreate: CaseCreate,
  CaseRunDetailPage: CaseInRun,
  FaqPage: FaqPage,
  CasePageUpdate: CaseUpdate,
  CasePage: Case,
  ProjectPageCasesDetail: () => <div>ProjectPageCasesDetail</div>,
  ProjectPageReports: ReportsPage,
  RunPageReport: ReportDetailPage,
  ProjectPageHistory: ProjectHistoryPage,
  ProjectPageRoles: ProjectRolesPage,
  ProjectPageIntegrations: ProjectIntegrationsPage,
  ProjectPageMembers: ProjectMembers,
  SharePage: () => <div>SharePage</div>,
  SharePageReports: ShareReportPage,
  SharePageRunReports: ShareReportRunPage,
  500: ErrorPage500,
  Page404: ErrorPage404,
  Page403: ErrorPage403,
  401: ErrorPage401
}

const PageStarter = (): React.ReactElement | null => {
  const { page: data } = usePageContext()
  const pageType = data?.pageType
  const page = data?.page
  if (pageType === undefined) {
    return null
  }
  const Page = PAGE_TYPES[pageType]
  return <Page {...page} />
}

const Combine = ({ staticContext }): React.ReactElement => {
  const location = useLocation()
  const store = useStores()

  return (
    <PageProvider
      scrollRestoration='auto'
      isScrollTo={false}
      fetchData={store.api.getPage}
      pathname={location.pathname}
      queryParams={location.search}
      initData={staticContext?.initData}
    >
      <CustomVh />
      <PageStarter />
      <Modals />
      <Toaster position='bottom-right' />
    </PageProvider>
  )
}

export default Combine
