import classNames from 'classnames'
import React, { useState } from 'react'
import Icon from '../Icon/Icon'
import { IAccordionProps } from './interface'
import style from './styles/accordion.module.scss'

const Accordion = ({
  head,
  children,
  defaultOpened = false,
  className = '',
  headClassName = '',
  headRight
}: IAccordionProps): React.ReactElement => {
  const [isOpen, setIsOpen] = useState(defaultOpened)

  const headClasses = classNames({
    [style.accordion__head]: true,
    [headClassName]: Boolean(headClassName)
  })

  const handleToggleOpened = (): void => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={className}>
      <button
        className={headClasses}
        type='button'
        onClick={handleToggleOpened}
      >
        {head}

        {headRight !== null && headRight !== undefined
          ? (
            <div className={style.accordion__right}>
              {headRight}
              <Icon
                src='arrow_down'
                className={classNames({
                  [style.accordion__icon]: true,
                  [style['accordion__icon--opened']]: isOpen
                })}
              />
            </div>
            )
          : (
            <Icon
              src='arrow_down'
              className={classNames({
                [style.accordion__icon]: true,
                [style['accordion__icon--opened']]: isOpen
              })}
            />
            )}
      </button>

      <div
        className={classNames({
          [style.accordion__body]: true,
          [style['accordion__body--opened']]: isOpen
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Accordion
