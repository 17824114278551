import React, { Suspense } from 'react'
import { errorFolder } from '@/images'
import AuthRegLayout from '../layout/components/AuthRegLayout'
import { useIntl } from 'react-intl'
import { PageLoader } from '@/components/Loading'

const ErrorUnauthorized = React.lazy(
  async () => await import('../Errors/ErrorUnauthorized')
)

const ErrorPage500 = (): React.ReactElement => {
  const intl = useIntl()

  return (
    <AuthRegLayout image={errorFolder}>
      <Suspense fallback={<PageLoader />}>
        <ErrorUnauthorized
          error={intl.formatMessage({
            id: 'error.error',
            defaultMessage: 'Error...'
          })}
          title={intl.formatMessage({
            id: 'error.text_500',
            defaultMessage: 'Your link works no longer'
          })}
          text={intl.formatMessage({
            id: 'error.contact_administrator',
            defaultMessage: 'Contact your administrator.'
          })}
        />
      </Suspense>
    </AuthRegLayout>
  )
}

export default ErrorPage500
