import { defaultCompany } from '@/images'
import classNames from 'classnames'
import React from 'react'
import style from './styles/avatar.module.scss'

interface IProps {
  src?: string | null
  size?: 'small' | 'medium' | 'large' | 'xl' | 'xxl' | 'xxxl'
  className?: string
}

const AvatarCompany = ({
  src = null,
  size = 'medium',
  className = ''
}: IProps): React.ReactElement => {
  return (
    <>
      <img
        className={classNames({
          [style.avatar]: true,
          [style[`avatar--${size}`]]: true,
          [style['border--light']]: true,
          [style[`border--${size}`]]: true,
          [style['avatar--square']]: true,
          [className]: Boolean(className)
        })}
        src={src ?? defaultCompany}
        alt='Company avatar'
      />
    </>
  )
}

export default AvatarCompany
