import React from 'react'
import Icon from '@/components/Icon/Icon'
import { FormattedMessage } from 'react-intl'
import { Spinner } from '@/components/Loading'
import TextButton from '@/components/Button/TextButton'
import style from '../styles/header.module.scss'
import Link from '@/components/Link'
import { IProject } from '@/interfaces/Project'

interface IProps {
  countCases: number
  handleCancel: () => void
  project: IProject
}

const Process = ({
  countCases,
  handleCancel,
  project
}: IProps): React.ReactElement => {
  return (
    <div className={style.download__item}>
      <div className={style.download__process}>
        <Spinner />

        <div className={style.download__description}>
          <span className={style.download__text}>
            <FormattedMessage
              id='download.export_file.process'
              defaultMessage='Generating export file from <project></project>:'
              values={{
                project: () => (
                  <Link
                    to={project.absolute_url}
                    className={style.download__projectlink}
                  >
                    {project.title}
                  </Link>
                )
              }}
            />
          </span>

          <span className={style.download__count}>
            <Icon src='test_case' />

            {countCases}
          </span>
        </div>
      </div>

      <TextButton className={style.download__linkbutton} onClick={handleCancel}>
        <FormattedMessage id='common.cancel' defaultMessage='Cancel' />
      </TextButton>
    </div>
  )
}
export default Process
