import React, { Suspense } from 'react'
import CommonLayout from '../layout/components/CommonLayout'
import { errorRobot } from '@/images'
import { useIntl } from 'react-intl'
import { PageLoader } from '@/components/Loading'

const Error404 = React.lazy(async () => await import('../Errors/Error404'))

const ErrorPage404 = (): React.ReactElement => {
  const intl = useIntl()

  return (
    <CommonLayout>
      <Suspense fallback={<PageLoader />}>
        <Error404
          numberError={404}
          textError={intl.formatMessage({
            id: 'error.error',
            defaultMessage: 'Error...'
          })}
          descriptionTitle={intl.formatMessage({
            id: 'error.page_not_found',
            defaultMessage: 'Page not found'
          })}
          descriprionText={intl.formatMessage({
            id: 'error.text_404',
            defaultMessage:
              'The page you are looking for does not exist, may be out of date or has been removed.'
          })}
          image={errorRobot}
        />
      </Suspense>
    </CommonLayout>
  )
}

export default ErrorPage404
