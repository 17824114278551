import React from 'react'
import ProjectMenuLink from './ProjectMenuLink'
import { ISidebarLink } from '@/apps/layout/interfaces'

interface IProps {
  linksArray: ISidebarLink[]
  isBig: boolean
  isInAccordion?: boolean
}

const ProjectMenuLinks = ({
  linksArray,
  isBig,
  isInAccordion
}: IProps): React.ReactElement => {
  return (
    <>
      {linksArray.map((el) => {
        return (
          <ProjectMenuLink
            key={el.name}
            isBig={isBig}
            name={el.name}
            href={el.href}
            icon={el.icon}
            isInAccordion={isInAccordion}
          />
        )
      })}
    </>
  )
}

export default ProjectMenuLinks
